import { Component } from '@angular/core';

@Component({
    selector: 'kno2-callback',
    template: '',
    standalone: false
})
export class CallbackComponent {
    constructor() {}
}
