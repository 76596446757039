import * as i0 from '@angular/core';
import { InjectionToken, Injectable, NgModule, importProvidersFrom } from '@angular/core';
import { setupListeners, QueryStatus, skipToken, defaultSerializeQueryArgs, buildCreateApi, coreModule, fetchBaseQuery as fetchBaseQuery$1 } from '@reduxjs/toolkit/query';
import * as i1 from '@ngrx/store';
import { provideState, createSelectorFactory, defaultMemoize } from '@ngrx/store';
import { isObservable, of, combineLatest, BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged, switchMap, tap, shareReplay, finalize } from 'rxjs/operators';
import { produceWithPatches } from 'immer';
let currentConfig;
const setupConfig = config => {
  currentConfig = config;
};
const defaultConfig = {
  setupListeners: false,
  baseUrl: ''
};
const STORE_RTK_QUERY_CONFIG = new InjectionToken('STORE_RTK_QUERY_CONFIG');
let service;
let delayedActions = [];
const internalBatchState = {
  currentSubscriptions: {}
};
function dispatch(action) {
  if (typeof action === 'function') {
    return action(dispatch, getState, {});
  } else if (action.type.endsWith('internal_probeSubscription')) {
    const queryCacheKey = action.payload.queryCacheKey;
    const requestId = action.payload.requestId;
    const hasSubscription = !!internalBatchState.currentSubscriptions[queryCacheKey]?.[requestId];
    return hasSubscription;
  }
  // Middleware dispatch actions before Store starts
  if (service && Object.keys(getState())?.length) {
    Promise.resolve().then(() => {
      delayedActions.map(delayedAction => service.dispatch(delayedAction));
      delayedActions = [];
      service.dispatch(action);
    });
  } else {
    delayedActions.push(action);
  }
  return action;
}
function getState() {
  return service?.getState();
}
function select(mapFn) {
  return service?.select(mapFn);
}
class ThunkService {
  constructor(store) {
    this.store = store;
    this.getState = this.store.selectSignal(state => state);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    service = this;
  }
  dispatch(action) {
    this.store.dispatch(action);
  }
  select(mapFn) {
    return this.store.select(mapFn);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function ThunkService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ThunkService)(i0.ɵɵinject(i1.Store));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ThunkService,
      factory: ThunkService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThunkService, [{
    type: Injectable
  }], function () {
    return [{
      type: i1.Store
    }];
  }, null);
})();
class StoreRtkQueryModule {
  static forRoot(config) {
    const moduleConfig = {
      ...defaultConfig,
      ...config
    };
    setupConfig(moduleConfig);
    if (moduleConfig.setupListeners) {
      setupListeners(dispatch, moduleConfig.customHandler);
    }
    return {
      ngModule: StoreRtkQueryModule,
      providers: [{
        provide: STORE_RTK_QUERY_CONFIG,
        useValue: moduleConfig
      }, ThunkService]
    };
  }
  constructor(thunkService) {
    this.thunkService = thunkService;
  }
  /** @nocollapse */
  static {
    this.ɵfac = function StoreRtkQueryModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || StoreRtkQueryModule)(i0.ɵɵinject(ThunkService));
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: StoreRtkQueryModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StoreRtkQueryModule, [{
    type: NgModule,
    args: [{}]
  }], function () {
    return [{
      type: ThunkService
    }];
  }, null);
})();
function provideStoreRtkQuery(config) {
  return importProvidersFrom(StoreRtkQueryModule.forRoot(config));
}
function provideStoreApi(api) {
  return provideState(api.reducerPath, api.reducer, {
    metaReducers: [api.metareducer]
  });
}
const UNINITIALIZED_VALUE = Symbol();
function useStableQueryArgs(queryArgs, serialize, endpointDefinition, endpointName, cacheRef = {}) {
  const incoming = {
    queryArgs,
    serialized: typeof queryArgs == 'object' ? serialize({
      queryArgs,
      endpointDefinition,
      endpointName
    }) : queryArgs
  };
  if (!cacheRef.current || cacheRef.current.serialized !== incoming.serialized) {
    cacheRef.current = incoming;
  }
  return cacheRef.current.queryArgs;
}
function capitalize(str) {
  return str.replace(str[0], str[0].toUpperCase());
}
function is(x, y) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  } else {
    return x !== x && y !== y;
  }
}
function shallowEqual(objA, objB) {
  if (is(objA, objB)) {
    return true;
  }
  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }
  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);
  if (keysA.length !== keysB.length) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < keysA.length; i++) {
    if (!Object.prototype.hasOwnProperty.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
      return false;
    }
  }
  return true;
}
function safeAssign(target, ...args) {
  Object.assign(target, ...args);
}

// const defaultQueryStateSelector: QueryStateSelector<any, any> = (x) => x;
const defaultMutationStateSelector = x => x;
/**
 * Wrapper around `defaultQueryStateSelector` to be used in `useQuery`.
 * We want the initial render to already come back with
 * `{ isUninitialized: false, isFetching: true, isLoading: true }`
 * to prevent that the library user has to do an additional check for `isUninitialized`/
 */
const noPendingQueryStateSelector = selected => {
  if (selected.isUninitialized) {
    return {
      ...selected,
      isUninitialized: false,
      isFetching: true,
      isLoading: selected.data !== undefined ? false : true,
      status: QueryStatus.pending
    };
  }
  return selected;
};
/**
 *
 * @param opts.api - An API with defined endpoints to create hooks for
 * @param opts.moduleOptions.useDispatch - The version of the `useDispatch` hook to be used
 * @param opts.moduleOptions.useSelector - The version of the `useSelector` hook to be used
 * @returns An object containing functions to generate hooks based on an endpoint
 */
function buildHooks({
  api,
  moduleOptions: {
    useDispatch: dispatch,
    useSelector
  },
  serializeQueryArgs,
  context
}) {
  return {
    buildQueryHooks,
    buildMutationHook,
    usePrefetch
  };
  function queryStatePreSelector(currentState, lastResult, queryArgs) {
    // if we had a last result and the current result is uninitialized,
    // we might have called `api.util.resetApiState`
    // in this case, reset the hook
    if (lastResult?.endpointName && currentState.isUninitialized) {
      const {
        endpointName
      } = lastResult;
      const endpointDefinition = context.endpointDefinitions[endpointName];
      if (serializeQueryArgs({
        queryArgs: lastResult.originalArgs,
        endpointDefinition,
        endpointName
      }) === serializeQueryArgs({
        queryArgs,
        endpointDefinition,
        endpointName
      })) lastResult = undefined;
    }
    // data is the last known good request result we have tracked
    // or if none has been tracked yet the last good result for the current args
    let data = currentState.isSuccess ? currentState.data : lastResult?.data;
    if (data === undefined) data = currentState.data;
    const hasData = data !== undefined;
    // isFetching = true any time a request is in flight
    const isFetching = currentState.isLoading;
    // isLoading = true only when loading while no data is present yet (initial load with no data in the cache)
    const isLoading = !hasData && isFetching;
    // isSuccess = true when data is present
    const isSuccess = currentState.isSuccess || isFetching && hasData;
    return {
      ...currentState,
      data,
      currentData: currentState.data,
      isFetching,
      isLoading,
      isSuccess
    };
  }
  function usePrefetch(endpointName, defaultOptions) {
    return (arg, options) => dispatch(api.util.prefetch(endpointName, arg, {
      ...defaultOptions,
      ...options
    }));
  }
  function buildQueryHooks(name) {
    const {
      initiate,
      select
    } = api.endpoints[name];
    const useQuerySubscription = (arg, {
      refetchOnReconnect,
      refetchOnFocus,
      refetchOnMountOrArgChange,
      skip = false,
      pollingInterval = 0
    } = {}, promiseRef = {}, argCacheRef = {}, lastRenderHadSubscription = {
      current: false
    }) => {
      const stableArg = useStableQueryArgs(skip ? skipToken : arg,
      // Even if the user provided a per-endpoint `serializeQueryArgs` with
      // a consistent return value, _here_ we want to use the default behavior
      // so we can tell if _anything_ actually changed. Otherwise, we can end up
      // with a case where the query args did change but the serialization doesn't,
      // and then we never try to initiate a refetch.
      defaultSerializeQueryArgs, context.endpointDefinitions[name], name, argCacheRef);
      const subscriptionOptions = {
        refetchOnReconnect,
        refetchOnFocus,
        pollingInterval
      };
      const {
        queryCacheKey,
        requestId
      } = promiseRef.current || {};
      // HACK Because the latest state is in the middleware, we actually
      // dispatch an action that will be intercepted and returned.
      let currentRenderHasSubscription = false;
      if (queryCacheKey && requestId) {
        // This _should_ return a boolean, even if the types don't line up
        const returnedValue = dispatch(api.internalActions.internal_probeSubscription({
          queryCacheKey,
          requestId
        }));
        if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
          if (typeof returnedValue !== 'boolean') {
            throw new Error(`Warning: Middleware for RTK-Query API at reducerPath "${api.reducerPath}" has not
              been added to the store. You must add the middleware for RTK-Query to function correctly!`);
          }
        }
        currentRenderHasSubscription = !!returnedValue;
      }
      const subscriptionRemoved = !currentRenderHasSubscription && lastRenderHadSubscription.current;
      lastRenderHadSubscription.current = currentRenderHasSubscription;
      if (subscriptionRemoved) {
        promiseRef.current?.unsubscribe();
        promiseRef.current = undefined;
      }
      if (stableArg !== skipToken) {
        const lastPromise = promiseRef?.current;
        const lastSubscriptionOptions = promiseRef.current?.subscriptionOptions;
        if (!lastPromise || lastPromise.arg !== stableArg) {
          lastPromise?.unsubscribe();
          promiseRef.current = dispatch(initiate(stableArg, {
            subscriptionOptions,
            forceRefetch: refetchOnMountOrArgChange
          }));
        } else if (!shallowEqual(subscriptionOptions, lastSubscriptionOptions)) {
          lastPromise.updateSubscriptionOptions(subscriptionOptions);
        }
      }
      return {
        /**
         * A method to manually refetch data for the query
         */
        refetch: () => {
          if (!promiseRef.current) throw new Error('Cannot refetch a query that has not been started yet.');
          return promiseRef.current?.refetch();
        }
      };
    };
    const useLazyQuerySubscription = ({
      refetchOnReconnect,
      refetchOnFocus,
      pollingInterval = 0
    } = {}, promiseRef = {}) => {
      let argState = UNINITIALIZED_VALUE;
      const subscriptionOptions = {
        refetchOnReconnect,
        refetchOnFocus,
        pollingInterval
      };
      const lastSubscriptionOptions = promiseRef.current?.subscriptionOptions;
      if (!shallowEqual(subscriptionOptions, lastSubscriptionOptions)) {
        promiseRef.current?.updateSubscriptionOptions(subscriptionOptions);
      }
      const trigger = (arg, {
        preferCacheValue = false
      } = {}) => {
        promiseRef.current?.unsubscribe();
        const promise = dispatch(initiate(arg, {
          subscriptionOptions,
          forceRefetch: !preferCacheValue
        }));
        promiseRef.current = promise;
        argState = arg;
        return promise;
      };
      /* if "cleanup on unmount" was triggered from a fast refresh, we want to reinstate the query */
      if (argState !== UNINITIALIZED_VALUE && !promiseRef.current) {
        trigger(argState, {
          preferCacheValue: true
        });
      }
      return [trigger, argState];
    };
    const useQueryState = (arg, {
      skip = false,
      selectFromResult
    } = {}, lastValue = {}, argCacheRef = {}) => {
      const arg$ = isObservable(arg) ? arg : of(arg);
      return arg$.pipe(map(currentArg => useStableQueryArgs(skip ? skipToken : currentArg, serializeQueryArgs, context.endpointDefinitions[name], name, argCacheRef)), distinctUntilChanged(shallowEqual), switchMap(stableArg => {
        const selectDefaultResult = createSelectorFactory(projector => defaultMemoize(projector, shallowEqual, shallowEqual))(select(stableArg), subState => queryStatePreSelector(subState, lastValue.current, stableArg));
        const querySelector = selectFromResult ? createSelectorFactory(projector => defaultMemoize(projector, shallowEqual, shallowEqual))(selectDefaultResult, selectFromResult) : selectDefaultResult;
        return useSelector(state => querySelector(state)).pipe(tap(() => lastValue.current = selectDefaultResult(getState())));
      }), shareReplay({
        bufferSize: 1,
        refCount: true
      }));
    };
    const useQuery = (arg, options) => {
      // Refs
      const promiseRef = {};
      const lastValue = {};
      const argRef = {};
      const arg$ = isObservable(arg) ? arg : of(arg);
      const options$ = isObservable(options) ? options : of(options);
      return combineLatest([arg$, options$.pipe(distinctUntilChanged((prev, curr) => shallowEqual(prev, curr)))]).pipe(switchMap(([currentArg, currentOptions]) => {
        const querySubscriptionResults = useQuerySubscription(currentArg, currentOptions, promiseRef, argRef);
        const queryStateResults$ = useQueryState(currentArg, {
          selectFromResult: currentArg === skipToken || currentOptions?.skip ? undefined : noPendingQueryStateSelector,
          ...currentOptions
        }, lastValue, argRef);
        return queryStateResults$.pipe(map(queryState => ({
          ...queryState,
          ...querySubscriptionResults
        })));
      }), shareReplay({
        bufferSize: 1,
        refCount: true
      }), finalize(() => {
        void promiseRef.current?.unsubscribe();
        promiseRef.current = undefined;
      }));
    };
    const useLazyQuery = options => {
      // Refs
      const promiseRef = {};
      const lastValue = {};
      const triggerRef = {};
      const argRef = {};
      const infoSubject = new BehaviorSubject({
        lastArg: UNINITIALIZED_VALUE
      });
      const info$ = infoSubject.asObservable();
      const options$ = isObservable(options) ? options : of(options);
      const state$ = combineLatest([options$.pipe(distinctUntilChanged((prev, curr) => shallowEqual(prev, curr)), tap(currentOptions => {
        const [trigger] = useLazyQuerySubscription(currentOptions, promiseRef);
        triggerRef.current = trigger;
      })), info$.pipe(tap(({
        lastArg,
        extra
      }) => {
        if (lastArg !== UNINITIALIZED_VALUE) {
          triggerRef.current?.(lastArg, extra);
        }
      }), map(({
        lastArg
      }) => lastArg), distinctUntilChanged(shallowEqual))]).pipe(switchMap(([currentOptions, currentArg]) => useQueryState(currentArg, {
        ...currentOptions,
        skip: currentArg === UNINITIALIZED_VALUE
      }, lastValue, argRef)), shareReplay({
        bufferSize: 1,
        refCount: true
      }), finalize(() => {
        void promiseRef.current?.unsubscribe();
        promiseRef.current = undefined;
      }));
      return {
        fetch: (arg, extra) => {
          infoSubject.next({
            lastArg: arg,
            extra
          });
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return promiseRef.current;
        },
        state$,
        lastArg$: info$.pipe(map(({
          lastArg
        }) => lastArg !== UNINITIALIZED_VALUE ? lastArg : skipToken))
      };
    };
    return {
      useQueryState,
      useQuerySubscription,
      useLazyQuerySubscription,
      useLazyQuery,
      useQuery,
      selector: select
    };
  }
  function buildMutationHook(name) {
    const {
      initiate,
      select
    } = api.endpoints[name];
    const useMutation = ({
      selectFromResult = defaultMutationStateSelector,
      fixedCacheKey
    } = {}) => {
      const promiseRef = {};
      const requestIdSubject = new BehaviorSubject(undefined);
      const requestId$ = requestIdSubject.asObservable();
      const triggerMutation = arg => {
        const promise = dispatch(initiate(arg, {
          fixedCacheKey
        }));
        if (!promiseRef.current?.arg.fixedCacheKey) {
          removePrevMutation();
        }
        promiseRef.current = promise;
        requestIdSubject.next(promise.requestId);
        return promise;
      };
      const reset = () => {
        removePrevMutation();
        requestIdSubject.next(undefined);
      };
      const removePrevMutation = () => {
        if (promiseRef.current) {
          dispatch(api.internalActions.removeMutationResult({
            requestId: promiseRef.current.requestId,
            fixedCacheKey
          }));
          promiseRef.current = undefined;
        }
      };
      const state$ = requestId$.pipe(finalize(() => {
        promiseRef.current?.reset();
        promiseRef.current = undefined;
      }), distinctUntilChanged(shallowEqual), switchMap(requestId => {
        const mutationSelector = createSelectorFactory(projector => defaultMemoize(projector, shallowEqual, shallowEqual))(select(requestId ? {
          fixedCacheKey,
          requestId
        } : skipToken), subState => selectFromResult(subState));
        const currentState = useSelector(state => mutationSelector(state));
        const originalArgs = fixedCacheKey == null ? promiseRef.current?.arg.originalArgs : undefined;
        return currentState.pipe(map(mutationState => ({
          ...mutationState,
          originalArgs,
          reset
        })));
      }), shareReplay({
        bufferSize: 1,
        refCount: true
      }));
      return {
        dispatch: triggerMutation,
        state$
      };
    };
    return {
      useMutation,
      selector: select
    };
  }
}

// Copied from https://github.com/feross/queue-microtask
let promise;
const queueMicrotaskShim = typeof queueMicrotask === 'function' ? queueMicrotask.bind(typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : globalThis) :
// reuse resolved promise, and allocate it lazily
cb => (promise || (promise = Promise.resolve())).then(cb).catch(err => setTimeout(() => {
  throw err;
}, 0));
const buildBatchedActionsHandler = ({
  api,
  internalState
}) => {
  const subscriptionsPrefix = `${api.reducerPath}/subscriptions`;
  let previousSubscriptions = null;
  let dispatchQueued = false;
  const {
    updateSubscriptionOptions,
    unsubscribeQueryResult
  } = api.internalActions;
  // Actually intentionally mutate the subscriptions state used in the middleware
  // This is done to speed up perf when loading many components
  const actuallyMutateSubscriptions = (mutableState, action) => {
    if (updateSubscriptionOptions.match(action)) {
      const {
        queryCacheKey,
        requestId,
        options
      } = action.payload;
      if (mutableState?.[queryCacheKey]?.[requestId]) {
        mutableState[queryCacheKey][requestId] = options;
      }
      return true;
    }
    if (unsubscribeQueryResult.match(action)) {
      const {
        queryCacheKey,
        requestId
      } = action.payload;
      if (mutableState[queryCacheKey]) {
        delete mutableState[queryCacheKey][requestId];
      }
      return true;
    }
    if (api.internalActions.removeQueryResult.match(action)) {
      delete mutableState[action.payload.queryCacheKey];
      return true;
    }
    if (action.type.endsWith('/pending')) {
      const {
        meta: {
          arg,
          requestId
        }
      } = action;
      if (arg.subscribe) {
        const substate = mutableState[arg.queryCacheKey] ??= {};
        substate[requestId] = arg.subscriptionOptions ?? substate[requestId] ?? {};
        return true;
      }
    }
    if (action.type.endsWith('/rejected')) {
      const {
        meta: {
          condition,
          arg,
          requestId
        }
      } = action;
      if (condition && arg.subscribe) {
        const substate = mutableState[arg.queryCacheKey] ??= {};
        substate[requestId] = arg.subscriptionOptions ?? substate[requestId] ?? {};
        return true;
      }
    }
    return false;
  };
  return (action, mwApi) => {
    if (!previousSubscriptions) {
      // Initialize it the first time this handler runs
      previousSubscriptions = JSON.parse(JSON.stringify(internalState.currentSubscriptions));
    }
    if (api.util.resetApiState.match(action)) {
      previousSubscriptions = internalState.currentSubscriptions = {};
      return [true, false];
    }
    // Intercept requests by hooks to see if they're subscribed
    // Necessary because we delay updating store state to the end of the tick
    if (api.internalActions.internal_probeSubscription.match(action)) {
      const {
        queryCacheKey,
        requestId
      } = action.payload;
      const hasSubscription = !!internalState.currentSubscriptions[queryCacheKey]?.[requestId];
      return [false, hasSubscription];
    }
    // Update subscription data based on this action
    const didMutate = actuallyMutateSubscriptions(internalState.currentSubscriptions, action);
    if (didMutate) {
      if (!dispatchQueued) {
        queueMicrotaskShim(() => {
          // Deep clone the current subscription data
          const newSubscriptions = JSON.parse(JSON.stringify(internalState.currentSubscriptions));
          // Figure out a smaller diff between original and current
          const [, patches] = produceWithPatches(previousSubscriptions, () => newSubscriptions);
          // Sync the store state for visibility
          mwApi.next(api.internalActions.subscriptionsUpdated(patches));
          // Save the cloned state for later reference
          previousSubscriptions = newSubscriptions;
          dispatchQueued = false;
        });
        dispatchQueued = true;
      }
      const isSubscriptionSliceAction = !!action.type?.startsWith(subscriptionsPrefix);
      const isAdditionalSubscriptionAction = action.type.endsWith('/rejected') && action.meta.condition && !!action.meta.arg.subscribe;
      const actionShouldContinue = !isSubscriptionSliceAction && !isAdditionalSubscriptionAction;
      return [actionShouldContinue, false];
    }
    return [true, false];
  };
};
function buildMetaReducer({
  api,
  moduleOptions: {
    useDispatch: dispatch
  }
}) {
  const anyApi = api;
  const reducerPath = anyApi.reducerPath;
  let nextState;
  const getState = () => nextState[reducerPath] ? nextState :
  // Query inside forFeature (Code splitting)
  {
    [reducerPath]: nextState
  };
  const middleware = anyApi.middleware({
    dispatch,
    getState
  })(getState);
  const batchedActionsHandler = buildBatchedActionsHandler({
    api,
    internalState: internalBatchState
  });
  return function (reducer) {
    return function (state, action) {
      nextState = reducer(state, action);
      if (action.type.startsWith(`${reducerPath}/`)) {
        batchedActionsHandler(action, {
          dispatch,
          getState,
          next: dispatch
        }, nextState);
      }
      middleware(action);
      return nextState;
    };
  };
}
function isQueryDefinition(e) {
  return e.type === 'query';
}
function isMutationDefinition(e) {
  return e.type === 'mutation';
}
const angularHooksModuleName = /* @__PURE__ */Symbol();
/**
 * Creates a module that generates hooks from endpoints, for use with `buildCreateApi`.
 *
 * @returns A module for use with `buildCreateApi`
 */
const angularHooksModule = ({
  useDispatch = dispatch,
  useSelector = select,
  getState: getState$1 = getState
} = {}) => ({
  name: angularHooksModuleName,
  init(api, {
    serializeQueryArgs
  }, context) {
    const anyApi = api;
    const {
      buildQueryHooks,
      buildMutationHook,
      usePrefetch
    } = buildHooks({
      api,
      moduleOptions: {
        useDispatch,
        useSelector,
        getState: getState$1
      },
      serializeQueryArgs,
      context
    });
    const metareducer = buildMetaReducer({
      api,
      moduleOptions: {
        useDispatch
      }
    });
    safeAssign(anyApi, {
      usePrefetch
    });
    safeAssign(anyApi, {
      metareducer
    });
    return {
      injectEndpoint(endpointName, definition) {
        if (isQueryDefinition(definition)) {
          const {
            useQuery,
            useLazyQuery,
            useLazyQuerySubscription,
            useQueryState,
            useQuerySubscription,
            selector
          } = buildQueryHooks(endpointName);
          safeAssign(anyApi.endpoints[endpointName], {
            useQuery,
            useLazyQuery,
            useLazyQuerySubscription,
            useQueryState,
            useQuerySubscription,
            selector
          });
          api[`use${capitalize(endpointName)}Query`] = useQuery;
          api[`useLazy${capitalize(endpointName)}Query`] = useLazyQuery;
        } else if (isMutationDefinition(definition)) {
          const {
            useMutation,
            selector
          } = buildMutationHook(endpointName);
          safeAssign(anyApi.endpoints[endpointName], {
            useMutation,
            selector
          });
          api[`use${capitalize(endpointName)}Mutation`] = useMutation;
        }
      }
    };
  }
});
const createApi = /* @__PURE__ */buildCreateApi(coreModule(), angularHooksModule());

/**
 * This is a very small wrapper around fetch that aims to simplify requests.
 *
 * @example
 * ```ts
 * const baseQuery = fetchBaseQuery({
 *   baseUrl: 'https://api.your-really-great-app.com/v1/',
 *   prepareHeaders: (headers, { getState }) => {
 *     const token = (getState() as RootState).auth.token;
 *     // If we have a token set in state, let's assume that we should be passing it.
 *     if (token) {
 *       headers.set('authorization', `Bearer ${token}`);
 *     }
 *     return headers;
 *   },
 * })
 * ```
 *
 * @param baseUrl
 * The base URL for an API service.
 * Typically in the format of https://example.com/
 *
 * @param prepareHeaders
 * An optional function that can be used to inject headers on requests.
 * Provides a Headers object, as well as most of the `BaseQueryApi` (`dispatch` is not available).
 * Useful for setting authentication or headers that need to be set conditionally.
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/API/Headers
 *
 * @param fetchFn
 * Accepts a custom `fetch` function if you do not want to use the default on the window.
 * Useful in SSR environments if you need to use a library such as `isomorphic-fetch` or `cross-fetch`
 *
 * @param paramsSerializer
 * An optional function that can be used to stringify querystring parameters.
 *
 * @param isJsonContentType
 * An optional predicate function to determine if `JSON.stringify()` should be called on the `body` arg of `FetchArgs`
 *
 * @param jsonContentType Used when automatically setting the content-type header for a request with a jsonifiable
 * body that does not have an explicit content-type header. Defaults to `application/json`.
 *
 * @param jsonReplacer Custom replacer function used when calling `JSON.stringify()`.
 *
 * @param timeout
 * A number in milliseconds that represents the maximum time a request can take before timing out.
 */
function fetchBaseQuery(queryArgs = {}) {
  const baseQueryEnvironment = fetchBaseQuery$1(queryArgs);
  return async (args, api, extraOptions) => {
    const baseUrl = currentConfig?.baseUrl ?? '';
    const argUrl = typeof args === 'string' ? args : args.url;
    const url = `${baseUrl}${argUrl}`;
    const newArgs = typeof args === 'string' ? url : {
      ...args,
      url
    };
    return await baseQueryEnvironment(newArgs, api, extraOptions);
  };
}

/*
 * Public API Surface of ngrx-rtk-query
 */

/**
 * Generated bundle index. Do not edit.
 */

export { StoreRtkQueryModule, ThunkService, angularHooksModule, createApi, dispatch, fetchBaseQuery, provideStoreApi, provideStoreRtkQuery };
