import { Component } from '@angular/core';

@Component({
    selector: 'kno2-unauthorized',
    templateUrl: './unauthorized.component.html',
    standalone: false
})
export class UnauthorizedComponent {
    constructor() {}
}
