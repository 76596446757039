import { Component, OnInit } from '@angular/core';
import { RoutingSliceFacade } from '@kno2/shared/data-access/+store';
import { take, switchMap } from 'rxjs/operators';
import { AuthService } from '../../services';
import { KNO2_AZURE_AD_CONNECTION, PCC_CONNECTION } from './login.constants';

@Component({
    selector: 'kno2-login',
    template: '',
    standalone: false
})
export class LoginComponent implements OnInit {
    constructor(private routingSlice: RoutingSliceFacade, private authService: AuthService) {}

    public ngOnInit(): void {
        this.routingSlice.routingData$
            .pipe(
                switchMap(({ state }) =>
                    this.authService.login({
                        authorizationParams: { connection: this.getConnectionName(state.params.loginType) }
                    })
                ),
                take(1)
            )
            .subscribe();
    }

    private getConnectionName(loginType: string): string {
        switch (loginType) {
            case 'azuread':
                return KNO2_AZURE_AD_CONNECTION;
            case 'pcc':
                return PCC_CONNECTION;
            default:
                return loginType;
        }
    }
}
