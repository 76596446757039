import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { NbLayoutComponent, NbOverlayContainerAdapter } from '@nebular/theme';
import { createNestedLayoutMutationObserver } from '../../utils/nested-layout-observer.util';

@Component({
    selector: 'ngx-app-frame-lite-layout',
    template: `
        <nb-layout>
            <nb-layout-header fixed>
                <ng-content select="ngx-header"></ng-content>
            </nb-layout-header>

            <nb-layout-column class="content-padding">
                <ng-content></ng-content>
            </nb-layout-column>
        </nb-layout>
    `,
    styleUrls: ['./app-frame-lite.layout.scss'],
    standalone: false
})
export class AppFrameLiteComponent implements AfterViewInit, OnDestroy {
    @ViewChild(NbLayoutComponent) public layoutComponent: NbLayoutComponent;
    public mutationObserver: MutationObserver;

    constructor(@Inject(DOCUMENT) private document: Document, private overlayContainerAdapter: NbOverlayContainerAdapter) {}

    public ngAfterViewInit(): void {
        this.mutationObserver = createNestedLayoutMutationObserver(
            this.document.body,
            this.layoutComponent['elementRef'],
            this.overlayContainerAdapter
        );
        this.mutationObserver.observe(this.document.body, {
            childList: true,
            subtree: true
        });
    }

    public ngOnDestroy(): void {
        this.mutationObserver.disconnect();
    }
}
