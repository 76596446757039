import { attachmentsReducer } from './+store/slices/attachments/attachments.slice';
import { conversationsApi, conversationsApiKey } from './+store/api/conversations/conversations.api';
import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { integrationsApi, integrationsApiKey } from './+store/api/integrations/integrations.api';
import { messagesApi, messagesApiKey } from './+store/api/messages/messages.api';
import { profileApi, profileApiKey } from './+store/api/profile/profile.api';
import { featuresApi, featuresApiKey } from './+store/api/features/features.api';
import { downloadsApi, downloadsApiKey } from './+store/api/downloads/downloads.api';
import { settingsApi, settingsApiKey } from './+store/api/settings/settings.api';
import { documentsApi, documentTypesApiKey } from './+store/api/document-types/document-types.api';
import { attachmentsApi, attachmentsApiKey } from './+store/api/attachments/attachments.api';
import { releaseTypesApiKey, releaseTypesApi } from './+store/api/release-types/release-types.api';
import { auditApi, auditApiKey } from './+store/api/audit/audit.api';
import { accountApi, accountApiKey } from './+store/api/account/account.api';
import { usersApi, usersApiKey } from './+store/api/users/users.api';
import { intakeApi, intakeApiKey } from './+store/api/intake/intake.api';
import { documentSourcesApi, documentSourcesApiKey } from './+store/api/document-sources/document-sources.api';
import { triageApi, triageApiKey } from './+store/api/triage/triage.api';
import { currentMessageSliceKey } from './+store/slices/current-message/current-message-slice.config';
import { currentMessageReducer } from './+store/slices/current-message/current-message.slice';
import { attachmentsSliceKey } from './+store/slices/attachments/attachments-slice.config';

@NgModule({
    imports: [
        StoreModule.forFeature(attachmentsSliceKey, attachmentsReducer),
        StoreModule.forFeature(currentMessageSliceKey, currentMessageReducer),
        StoreModule.forFeature(accountApiKey, accountApi.reducer, {
            metaReducers: [accountApi.metareducer]
        }),
        StoreModule.forFeature(auditApiKey, auditApi.reducer, {
            metaReducers: [auditApi.metareducer]
        }),
        StoreModule.forFeature(documentSourcesApiKey, documentSourcesApi.reducer, {
            metaReducers: [documentSourcesApi.metareducer]
        }),
        StoreModule.forFeature(featuresApiKey, featuresApi.reducer, { metaReducers: [featuresApi.metareducer] }),
        StoreModule.forFeature(integrationsApiKey, integrationsApi.reducer, {
            metaReducers: [integrationsApi.metareducer]
        }),
        StoreModule.forFeature(intakeApiKey, intakeApi.reducer, { metaReducers: [intakeApi.metareducer] }),
        StoreModule.forFeature(usersApiKey, usersApi.reducer, { metaReducers: [usersApi.metareducer] }),
        StoreModule.forFeature(messagesApiKey, messagesApi.reducer, { metaReducers: [messagesApi.metareducer] }),
        StoreModule.forFeature(profileApiKey, profileApi.reducer, { metaReducers: [profileApi.metareducer] }),
        StoreModule.forFeature(downloadsApiKey, downloadsApi.reducer, {
            metaReducers: [downloadsApi.metareducer]
        }),
        StoreModule.forFeature(settingsApiKey, settingsApi.reducer, {
            metaReducers: [settingsApi.metareducer]
        }),
        StoreModule.forFeature(documentTypesApiKey, documentsApi.reducer, { metaReducers: [documentsApi.metareducer] }),
        StoreModule.forFeature(attachmentsApiKey, attachmentsApi.reducer, {
            metaReducers: [attachmentsApi.metareducer]
        }),
        StoreModule.forFeature(releaseTypesApiKey, releaseTypesApi.reducer, {
            metaReducers: [releaseTypesApi.metareducer]
        }),
        StoreModule.forFeature(triageApiKey, triageApi.reducer, {
            metaReducers: [triageApi.metareducer]
        }),
        StoreModule.forFeature(conversationsApiKey, conversationsApi.reducer, {
            metaReducers: [conversationsApi.metareducer]
        })
    ],
    providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class SharedApiKno2fyMainModule {}
