import { NgModule, provideAppInitializer } from '@angular/core';
import { IntercomService } from './intercom/intercom.service';

@NgModule({
    providers: [
        provideAppInitializer(() => {
            const initializerFn = IntercomService.init();
            return initializerFn();
        })
    ]
})
export class SharedUtilIntegrationsModule {}
