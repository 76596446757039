import { Component, OnDestroy, Input } from '@angular/core';
import { NbLayoutDirectionService, NbLayoutDirection } from '@nebular/theme';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'ngx-layout-direction-switcher',
    template: `
        <ngx-switcher
            [firstValue]="directions.RTL"
            [secondValue]="directions.LTR"
            [firstValueLabel]="'RTL'"
            [secondValueLabel]="'LTR'"
            [value]="currentDirection"
            (valueChange)="toggleDirection($event)"
            [vertical]="vertical"></ngx-switcher>
    `,
    standalone: false
})
export class LayoutDirectionSwitcherComponent implements OnDestroy {
    protected destroy$ = new Subject<void>();

    public directions = NbLayoutDirection;
    public currentDirection: NbLayoutDirection;

    @Input() public vertical = false;

    constructor(private directionService: NbLayoutDirectionService) {
        this.currentDirection = this.directionService.getDirection();

        this.directionService
            .onDirectionChange()
            .pipe(takeUntil(this.destroy$))
            .subscribe((newDirection) => (this.currentDirection = newDirection));
    }

    public toggleDirection(newDirection: NbLayoutDirection): void {
        this.directionService.setDirection(newDirection);
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
