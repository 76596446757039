import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NbToastrModule } from '@nebular/theme';
import { APIInterceptor } from './interceptors/api.interceptor';

@NgModule({
    imports: [NbToastrModule.forRoot()],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: APIInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class SharedUtilConfigurationModule {}
